<template>
  <transition name="imageModal" appear>
    <div class="bl_modal" @click.self="close()">
      <div class="bl_modal_content" @click.self="close()">
        <label class="modal_close" @click="close()">×</label>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">

export default {
  methods: {
    close(){
      this.$emit('closeModal');
      console.log('ok')
    }
  }
}
</script>

<style lang="css" scoped>
.bl_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding-top: 40px;
}

.bl_modal .modal_close {
  color: #fff;
  top: 0;
  right: 20px;
  position: absolute;
  font-size: 50px;
}

.bl_modal .bl_modal_content {
  width: 50%;
  height: 100%;
  overflow: auto;
}

.bl_modal .bl_modal_content .modal_manga {
  width: 100%;
  pointer-events: none;
}

@media screen and (max-width: 750px) {
  .bl_modal .bl_modal_content {
    width: 75%;
  }
}
</style>